
import {computed, defineComponent} from 'vue'
import {ClientSelect} from "@/core/composite/composite";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "BaseClientSelect",
  components: {BaseSelect},
  props:{
    modelValue:{}
  },
  setup(props, {emit}) {
    const model = computed<any>({
      get()  {
        return props.modelValue
      },
      set(model) {
        emit('update:modelValue', model)
      }
    })
    return {
      model,
      ...ClientSelect(false)
    }
  }
})
